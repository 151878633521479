<div class="formCommentContainer">
  <h3>Deixe um comentário!</h3>

  <form
    (ngSubmit)="submit()"
    [formGroup]="commentForm"
    #formDir="ngForm"
    action=""
  >
    <label for="name">Nome:</label>
    <input formControlName="name" required id="name" type="text" />
    <p *ngIf="comment.invalid && formDir.submitted" class="validation-error">
      É obrigatório passar seu nome.
    </p>
    <label for="coment">Comentário:</label>
    <input formControlName="comment" required id="coment" type="text" />
    <p *ngIf="comment.invalid && formDir.submitted" class="validation-error">
      É obrigatório passar seu comentário.
    </p>
    <input class="buttonSend" type="submit" />
  </form>
</div>
