import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = environment.api;

  constructor(private http: HttpClient) {}

  getData(
    page: number,
    limit: number,
    queryParams?: { [key: string]: any }
  ): Observable<any> {
    let url = `${this.apiUrl}/content?page=${page}&limit=${limit}`;

    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          url += `&${key}=${queryParams[key]}`;
        }
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(url, httpOptions);
  }

  createUpload(formData: FormData): Observable<any> {
    return this.http
      .post<FormData>(this.apiUrl + '/content', formData, {
        observe: 'response',
      })
      .pipe(
        catchError((error: any) => {
          const customResponse = {
            status: error.status,
            message: error?.error?.message || 'Ocorreu um erro na requisição.',
          };
          alert(customResponse.message);
          return of(new HttpResponse(customResponse));
        })
      );
  }

  createComment(formData: any): Observable<HttpResponse<any>> {
    return this.http
      .post<FormData>(this.apiUrl + '/comment', formData, {
        observe: 'response',
      })
      .pipe(
        catchError((error: any) => {
          const customResponse = {
            status: error.status,
            message: error?.error?.message || 'Ocorreu um erro na requisição.',
          };
          alert(customResponse.message);
          return of(new HttpResponse(customResponse));
        })
      );
  }
}
