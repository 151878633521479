import { Component } from '@angular/core';

import { DataService } from 'src/app/services/repository.service';

interface cardsProps {
  imageurl: string;
  text: string;
  link: string;
  description: string;
}

interface eventCommentProps {
  name: string;
  comment: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  cards: cardsProps[] = [
    {
      imageurl: '/assets/cardsImages/2.png',
      text: 'Antologia de Poemas Musicados',
      link: '/antologia',
      description:
        'Nesta seção você terá acesso a um livro digital, com poemas, comentários sobre eles, cifras musicais, partituras e áudios dos poemas musicados, que retratam identidades da Latitude 21 Nova Alta Paulista.',
    },
    {
      imageurl: '/assets/cardsImages/3.png',
      text: 'Jatobateca',
      link: '/jatobateca',
      description:
        'Esta seção traz um compilado, sempre inconcluso, de informações e conteúdos acerca do fruto nativo jatobá.',
    },
    {
      imageurl: '/assets/cardsImages/4.png',
      text: 'Repositório de publicações virtuais com temáticas regionais',
      link: '/repositorio',
      description:
        'Nesta seção você terá acesso a um compilado de publicações de várias naturezas, referentes a temáticas que envolvem a Latitude 21 Nova Alta Paulista.',
    },
    {
      imageurl: '/assets/cardsImages/6.png',
      text: 'Latitude 21 Nova Alta Paulista em vídeos',
      link: '/videos',
      description:
        'Nesta seção você terá acesso a informações, memórias, curiosidades e muito mais, disponibilizadas por pessoas com histórias para contar sobre suas vivências, suas crenças e habilidades.',
    },
    {
      imageurl: '/assets/cardsImages/7.png',
      text: 'Acervo coletivo de fotografias regionais',
      link: '/fotos',
      description:
        'Em muitos cliques de colaboradores despojados, as fotografias podem te levar aos nossos campos e cidades, em viagens inspiradoras.',
    },
    {
      imageurl: '/assets/cardsImages/8.png',
      text: 'Literatura regional',
      link: '/literatura-regional',
      description:
        'Nesta seção você terá acesso à relação de obras impressas criadas por escritores regionais, bem como onde podem ser encontradas para consulta e leitura.',
    },
    {
      imageurl: '/assets/cardsImages/logoemporio.png',
      text: 'Empório Flamejante',
      link: 'https://emporioflamejante.com.br/',
      description:
        'Empório Flamejante é uma plataforma de e.commerce que traz inovações com jatobá e criações de dezenas de microempreendedores da Latitude 21 Nova Alta Paulista. Conheça pretigie, compre, presenteie, divulgue. É por meio do consumo que o desenvolvimento de base local acontece. Faça parte dessa iniciativa.',
    },
    {
      imageurl: '/assets/cardsImages/3.png',
      text: 'Projeto Nossa Gente',
      link: '/projeto-nossa-gente',
      description:
        '',
    },
  ];

  constructor(private DataService: DataService) { }

  async createHandler(event: any) {
    const formData = new FormData();

    formData.append('author', event.name);
    formData.append('email', event.email);
    formData.append('city', event.city);
    formData.append('phone', event.number);
    formData.append('contentType', event.content);
    formData.append('source', "CONTRIBUTION");

    if (event.file) {
      formData.append('file', event.file);
    }

    this.DataService.createUpload(formData).subscribe((response) => {
      if (response.status === 200) {
        alert('Arquivo Enviado!');
      }
    });

    console.log(event, 'Evento');
  }

  async createHandlerComment(event: any) {
    let eventValues: eventCommentProps = {
      name: event.name,
      comment: event.comment,
    };

    this.DataService.createComment(eventValues).subscribe((response) => {
      if (response.status === 200) {
        alert('Comentário Enviado!');
      }

    });
  }
}
