import { Component } from '@angular/core';

@Component({
  selector: 'app-jatobateca',
  templateUrl: './jatobateca.component.html',
  styleUrls: ['./jatobateca.component.scss'],
})
export class JatobatecaComponent {
  title: string = 'Jatobateca';
}
