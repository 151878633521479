<app-slider-header></app-slider-header>

<div class="aboutProjectContainer">
  <div class="aboutProjectWrapper">
    <h3 class="aboutTitle">Sobre o projeto</h3>
    <p class="about">
      O movimento sociocultural e ambiental Latitude 21 nasceu em 2019, com o
      objetivo de identificar e representar algumas das identidades regionais,
      por meio de estudos científicos, da literatura, da música e da fotografia.
      O movimento tem como recorte espacial trinta municípios, que se
      autodenominam Nova Alta Paulista.
    </p>
    <div class="linkWrapper">
      <a class="link" href="/sobre-nos">Saiba mais</a>
    </div>
  </div>
  <div class="aboutWebSiteWrapper">
    <h3 class="aboutTitle second">Sobre o site</h3>
    <p class="about second">
      Este repositório estruturado tem como finalidade dar suporte para
      pesquisas científicas, trabalhos escolares, inspiração artística e
      cultural, tornandose parte da memória regional de uma pequena área do
      estado de São Paulo, que se autodenomina Nova Alta Paulista.
    </p>
  </div>
</div>

<div href="#" class="contentHome">
  <div class="containerCards">
    <a *ngFor="let card of cards" href="{{ card.link }}"
      [target]="card.text === 'Empório Flamejante' ? '_blank' : '_self'" class="card">
      <div class="image">
        <img src="{{ card.imageurl }}" alt="" />
      </div>
      <div class="text">
        <p>{{ card.text }}</p>
      </div>
      <div class="hoverTextContainer">
        <p class="hoverText">{{ card.description }}</p>
      </div>
    </a>
  </div>

  <app-form-home (onSubmit)="createHandler($event)"></app-form-home>

  <app-downloadapp></app-downloadapp>

  <app-comment-component (onSubmit)="createHandlerComment($event)"></app-comment-component>
</div>