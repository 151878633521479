import { Component } from '@angular/core';

@Component({
  selector: 'app-jatobateca',
  templateUrl: './literaturaRegional.component.html',
  styleUrls: ['./literaturaRegional.component.scss'],
})
export class LiteraturaRegionalComponent {
  title: string = 'Literatura Regional';
}
