import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-home',
  templateUrl: './form-home.component.html',
  styleUrls: ['./form-home.component.scss'],
})
export class FormHomeComponent {
  @Output() onSubmit = new EventEmitter();

  momentForm!: FormGroup;

  ngOnInit(): void {
    this.momentForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      content: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    });
  }

  get name() {
    return this.momentForm.get('name')!;
  }
  get email() {
    return this.momentForm.get('email')!;
  }
  get city() {
    return this.momentForm.get('city')!;
  }
  get number() {
    return this.momentForm.get('number')!;
  }
  get content() {
    return this.momentForm.get('content')!;
  }
  get file() {
    return this.momentForm.get('file')!;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    this.momentForm.patchValue({ file: file });
  }

  submit() {
    if (this.momentForm.valid) {
      this.onSubmit.emit(this.momentForm.value);

      this.momentForm.reset();
      Object.keys(this.momentForm.controls).forEach((key) => {
        this.momentForm.get(key)?.setErrors(null);
        this.momentForm.get(key)?.markAsPristine();
        this.momentForm.get(key)?.markAsUntouched();
      });

      const fileInput = document.querySelector<HTMLInputElement>('#file');
      if (fileInput) {
        fileInput.value = '';
      }

    } else {
    }
  }
}
