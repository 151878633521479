import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-comment-component',
  templateUrl: './comment-component.component.html',
  styleUrls: ['./comment-component.component.scss'],
})
export class CommentComponentComponent {
  @Output() onSubmit = new EventEmitter();

  commentForm!: FormGroup;

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    });
  }

  get name() {
    return this.commentForm.get('name')!;
  }

  get comment() {
    return this.commentForm.get('comment')!;
  }

  submit() {
    if (this.commentForm.valid) {
      this.onSubmit.emit(this.commentForm.value);

      this.commentForm.reset();
      Object.keys(this.commentForm.controls).forEach((key) => {
        this.commentForm.get(key)?.setErrors(null);
        this.commentForm.get(key)?.markAsPristine();
        this.commentForm.get(key)?.markAsUntouched();
      });
    }
  }
}
