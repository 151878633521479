<div class="containerHeader">
  <img class="firstImage" src="/assets/headerSand.png" alt="" />
  <a href="/">
    <img class="imagemHeader" src="/assets/headerLatitude.png" alt=""
  /></a>
  <img
    class="imagemHeaderMobile"
    src="/assets/headerLatitudeMobile.png"
    alt=""
  />
</div>
<div class="wrapperTitle">
  <h1 class="title">
    {{ titleName }}
  </h1>
</div>
