import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-slider-header',
  templateUrl: './slider-header.component.html',
  styleUrls: ['./slider-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderHeaderComponent {
  photos: string[] = [
    '/assets/sliderHomePhotos/1.png',
    '/assets/sliderHomePhotos/2.png',
    '/assets/sliderHomePhotos/3.png',
    '/assets/sliderHomePhotos/4.png',
    '/assets/sliderHomePhotos/5.png',
    '/assets/sliderHomePhotos/6.png',
    '/assets/sliderHomePhotos/7.png',
    '/assets/sliderHomePhotos/8.png',
    '/assets/sliderHomePhotos/9.png',
    '/assets/sliderHomePhotos/10.png',
    '/assets/sliderHomePhotos/11.png',
    '/assets/sliderHomePhotos/12.png',
    '/assets/sliderHomePhotos/13.png',
    '/assets/sliderHomePhotos/14.png',
    '/assets/sliderHomePhotos/15.png',
    '/assets/sliderHomePhotos/16.png',
    '/assets/sliderHomePhotos/17.png',
    '/assets/sliderHomePhotos/18.png',
  ];
}
