import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { RepositoryComponent } from './pages/repository/repository.component';
import { LiteraturaComponent } from './pages/literatura/literatura.component';
import { VideosComponent } from './pages/videos/videos.component';
import { FotosComponent } from './pages/fotos/fotos.component';
import { AntologiaComponent } from './pages/antologia/antologia.component';
import { JatobatecaComponent } from './pages/jatobateca/jatobateca.component';
import { LiteraturaRegionalComponent } from './pages/literaturaRegional/literaturaRegional.component';
import { ProjetoNossaGenteComponent } from './pages/projetoNossaGente/projetoNossaGente.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'sobre-nos', component: AboutUsComponent },
  { path: 'repositorio', component: RepositoryComponent },
  { path: 'literaturas', component: LiteraturaComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'fotos', component: FotosComponent },
  { path: 'antologia', component: AntologiaComponent },
  { path: 'jatobateca', component: JatobatecaComponent },
  { path: 'literatura-regional', component: LiteraturaRegionalComponent },
  { path: 'projeto-nossa-gente', component: ProjetoNossaGenteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
