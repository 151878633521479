<app-header [titleName]="title"></app-header>
<div class="containerLiteraturas">


  <iframe src="https://drive.google.com/embeddedfolderview?id=1-UB-_fnk_2qtytaIcIif-ndJ3h2bFvSz#grid"
    style="width:100%; height:600px; border:0;"></iframe>

  <iframe src="https://drive.google.com/embeddedfolderview?id=1Y0Rk_k7ZEL62CKpnJTV3F87jCfldCowa#grid"
    style="width:100%; height:600px; border:0;"></iframe>


  <h3>Relatórios</h3>

  <iframe src="https://drive.google.com/embeddedfolderview?id=1uaVDUOlluAzNpzZh7mkJ9zsDqqvh-GBS#grid"
    style="width:100%; height:600px; border:0;"></iframe>

  <h3>Relatórios</h3>

  <iframe src="https://drive.google.com/embeddedfolderview?id=1u2qv62HHdXRcEUc9vkkActw0OYAyR8bM#grid"
    style="width:100%; height:600px; border:0;"></iframe>

  <h3>Vídeos Desenv. Local e Reg</h3>

  <iframe src="https://drive.google.com/embeddedfolderview?id=1z6rGM_-kJ863L8Our1UmMg6m56QAFUPl#grid"
    style="width:100%; height:600px; border:0;"></iframe>

</div>