<app-header [titleName]="title"></app-header>
<div class="ContainerRepository">
  <p>
    A sessão está dividida em duas partes. Uma delas traz a relação de autores e
    obras físicas das trinta cidades que compõem a Nova Alta Paulista, indicando
    onde podem ser encontradas. A outra parte traz um compilado de trabalhos
    publicados em ambiente virtual, sob diferentes temáticas. Sempre inconcluso,
    o compilado tem a finalidade de reunir as principais publicações referentes
    à área em destaque, servindo de subsídio para estudos, inspirações
    literárias e memórias afetivas.
  </p>

  <iframe src="https://drive.google.com/embeddedfolderview?id=1tEFdAqKwcEk-sQH2JOsBMEIoj0BAk6zd#grid"
    style="width:100%; height:600px; border:0;"></iframe>

  <!-- <div class="ObrasContainer">
    <h3>Obras</h3>

    <table>
      <tr>
        <th>Nome do Arquivo</th>
        <th>Tamanho</th>
        <th>Tipo do Arquivo</th>
        <th>Data de Upload</th>
        <th>Autor</th>
        <th>Download</th>
      </tr>
      <tbody>
        <tr *ngFor="let item of data">
          <td>{{ item._id }}</td>
          <td>{{ item.file.sizeStr }}</td>
          <td>{{ item.file.mimeType }}</td>
          <td>{{ item.createdAt | date : "dd/MM/yyyy HH:mm" }}</td>
          <td>{{ item.author }}</td>
          <td>LINK DOWNLOAD</td>
        </tr>
      </tbody>
    </table>

    <ul class="pagination">
      <li *ngFor="let page of createPageArray(getPageNumberLat21())" [class.active]="page === currentPage">
        <a (click)="onPageChangeLat21(page)">{{ page }}</a>
      </li>
    </ul>
  </div>

  <div class="ContribuicoesContainer">
    <h3>Contribuições</h3>

    <table>
      <tr>
        <th>Nome do Arquivo</th>
        <th>Tamanho</th>
        <th>Tipo do Arquivo</th>
        <th>Data de Upload</th>
        <th>Autor</th>
        <th>Download</th>
      </tr>
      <tbody>
        <tr *ngFor="let item of dataContribuition">
          <td>{{ item._id }}</td>
          <td>{{ item.file.sizeStr }}</td>
          <td>{{ item.file.mimeType }}</td>
          <td>{{ item.createdAt | date : "dd/MM/yyyy HH:mm" }}</td>
          <td>{{ item.author }}</td>
          <td>LINK DOWNLOAD</td>
        </tr>
      </tbody>
    </table>

    <ul class="pagination">
      <li *ngFor="let page of createPageArray(getPageNumberContribuition())"
        [class.active]="page === currentPageContribuition">
        <a (click)="onPageChangeContribuition(page)">{{ page }}</a>
      </li>
    </ul>
  </div> -->
</div>