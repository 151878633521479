import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { register } from 'swiper/element/bundle';
import { FooterComponent } from './components/footer/footer.component';
import { SliderHeaderComponent } from './components/slider-header/slider-header.component';
import { HeaderComponent } from './components/header/header.component';
import { FormHomeComponent } from './components/form-home/form-home.component';
import { DownloadappComponent } from './components/downloadapp/downloadapp.component';
import { CommentComponentComponent } from './components/comment-component/comment-component.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { RepositoryComponent } from './pages/repository/repository.component';
import { LiteraturaComponent } from './pages/literatura/literatura.component';
import { VideosComponent } from './pages/videos/videos.component';
import { FotosComponent } from './pages/fotos/fotos.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AntologiaComponent } from './pages/antologia/antologia.component';
import { JatobatecaComponent } from './pages/jatobateca/jatobateca.component';
import { LiteraturaRegionalComponent } from './pages/literaturaRegional/literaturaRegional.component';
import { ProjetoNossaGenteComponent } from './pages/projetoNossaGente/projetoNossaGente.component';

register();

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    SliderHeaderComponent,
    HeaderComponent,
    FormHomeComponent,
    // DownloadappComponent,
    CommentComponentComponent,
    AboutUsComponent,
    RepositoryComponent,
    LiteraturaComponent,
    VideosComponent,
    FotosComponent,
    AntologiaComponent,
    JatobatecaComponent,
    LiteraturaRegionalComponent,
    ProjetoNossaGenteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
