<h3 class="titleFormUpload">Contribua para o repositório</h3>
<p class="descriptionUpload">
  Se você é autor/a de alguma <strong>obra acadêmica ou literária</strong>, ou
  registrou alguma <strong>imagem fotográfica</strong> ou
  <strong>vídeo</strong> que tenha como referência alguma das cidades da
  <strong>Nova Alta Paulista</strong>, upe no nosso repositório e compartilhe
  com mais pessoas!
</p>
<form (ngSubmit)="submit()" [formGroup]="momentForm" #formDir="ngForm" class="formRepository" action="">
  <div class="firstPart">
    <label for="name">Nome:</label>
    <input id="name" type="text" formControlName="name" required />
    <p class="validation-error" *ngIf="name.invalid && formDir.submitted">
      O nome é obrigatório
    </p>
  </div>
  <div class="secondPart">
    <div class="leftSide">
      <label for="email">E-mail:</label>
      <input id="email" type="email" formControlName="email" required />

      <p class="validation-error" *ngIf="email.invalid && formDir.submitted">
        O campo de email é obrigatório.
      </p>

      <label for="city">Cidade e Estado:</label>
      <input id="city" type="text" formControlName="city" required />
      <p class="validation-error" *ngIf="city.invalid && formDir.submitted">
        O campo cidade é obrigatório.
      </p>
    </div>
    <div class="rightSide">
      <label for="number">Contato:</label>
      <input id="number" type="number" formControlName="number" required />

      <p class="validation-error" *ngIf="number.invalid && formDir.submitted">
        O campo contato é obrigatório.
      </p>

      <label for="content">Tipo de conteúdo:</label>
      <select formControlName="content" id="content">
        <option value="SEARCH">Pesquisa acadêmica</option>
        <option value="IMAGE">Imagem</option>
        <option value="VIDEO">Vídeo</option>
      </select>
      <p class="validation-error" *ngIf="content.invalid && formDir.submitted">
        O campo de conteúdo é obrigatório.
      </p>
    </div>
  </div>

  <div class="thirdPart">
    <label for="">Upe o conteúdo aqui:</label>
    <div class="wrapperUpload">
      <input (change)="onFileSelected($event)" id="file" type="file" />
    </div>
    <p class="validation-error" *ngIf="file.invalid && formDir.submitted">
      É necessário escolher algum arquivo.
    </p>
  </div>

  <input class="submitButton" type="submit" value="Enviar" />
</form>