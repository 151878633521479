import { Component } from '@angular/core';

@Component({
  selector: 'app-projetoNossaGente',
  templateUrl: './projetoNossaGente.component.html',
  styleUrls: ['./projetoNossaGente.component.scss'],
})
export class ProjetoNossaGenteComponent {
  title: string = 'Projeto Nossa Gente';
}
