import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/repository.service';
import { docsProps, queryParamsProps } from './types/types';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.scss'],
})
export class RepositoryComponent implements OnInit {
  title: string = 'Repositório de publicações virtuais com temáticas regionais';

  data: docsProps[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: number = 0;
  totalPagesLat21: number = 0;
  dataOne: any;
  queryParams: queryParamsProps = {
    contentType: 'SEARCH',
    source: 'CONTRIBUTION',
  };

  dataContribuition: docsProps[] = [];
  currentPageContribuition: number = 1;
  itemsPerPageContribuition: number = 5;
  totalItemsContribuition: number = 0;
  totalPagesContribuition: number = 0;
  dataOneContribuition: any;
  queryParamsContribuition: queryParamsProps = {
    contentType: 'SEARCH',
    source: 'CONTRIBUTION',
  };

  constructor(private DataService: DataService) {}

  ngOnInit(): void {
    this.loadPageLat21(this.currentPage);
    this.loadPageContribuition(this.currentPageContribuition);
  }

  loadPageContribuition(page: number): void {
    this.DataService.getData(
      page,
      this.itemsPerPageContribuition,
      this.queryParamsContribuition
    ).subscribe((data: any) => {
      this.dataContribuition = data.docs;
      this.totalPagesContribuition = data.totalPages;
    });
  }

  getPageNumberContribuition(): number {
    return this.totalPagesContribuition;
  }

  onPageChangeContribuition(page: number): void {
    this.currentPageContribuition = page;
    this.loadPageContribuition(page);
  }

  loadPageLat21(page: number): void {
    this.DataService.getData(
      page,
      this.itemsPerPage,
      this.queryParams
    ).subscribe((data: any) => {
      this.data = data.docs;
      this.totalPagesLat21 = data.totalPages;
      console.log('data', data);
      console.log('this.totalPages', this.totalPagesLat21);
    });
  }

  getPageNumberLat21(): number {
    return this.totalPagesLat21;
  }

  createPageArray(totalPages: number): number[] {
    const pageArray = [];
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push(i);
    }
    return pageArray;
  }

  onPageChangeLat21(page: number): void {
    this.currentPage = page;
    this.loadPageLat21(page);
  }
}
