import { Component } from '@angular/core';

@Component({
  selector: 'app-literatura',
  templateUrl: './literatura.component.html',
  styleUrls: ['./literatura.component.scss'],
})
export class LiteraturaComponent {
  title: string = 'Literatura Regional';
}
