<app-header [titleName]="title"></app-header>
<div class="contentContainerAboutUs">
  <p>
    O movimento sociocultural e ambiental Latitude 21 nasceu em 2019, com o
    objetivo de identificar e representar algumas das identidades regionais, por
    meio de estudos científicos, da literatura, música e da fotografia. O
    movimento é formado por trinta municípios, as quais se autodenominam Nova
    Alta Paulista.
  </p>
  <p>
    Em relação à literatura, em 2019, foi publicada a 1a Antologia de Contos,
    Crônicas e Poemas Regionais da Nova Alta Paulista, pela Gráfica Impress, com
    a participação de 55 escritores de 13 cidades, totalizando 85 trabalhos
    inéditos. Pessoas de diferentes idades, segmentos sociais e graus de
    escolaridade escreveram suas memórias, resultando num trabalho seminal.
  </p>
  <p>
    Em 2020 e 2022, mais especificamente durante o período pandêmico Covid-19,
    13 escritores de 9 cidades escreveram poemas inspirados em temáticas
    regionais. Em parceria com músicos profissionais, somam 40 poemas musicados,
    gravados e com direitos autorais registrados.
  </p>
  <p>
    Em 2023, está em editoração a 2a Antologia de Contos, Poemas e Crônicas
    Regionais da Nova Alta Paulista, subintitulada Poemas Musicados. A obra tem
    formato eletrônico, reunindo os poemas, comentários literários, histórico e
    musical dos mesmos, cifras musicais e partituras e também os áudios,
    resultando em mais uma obra inédita.
  </p>
  <p>
    Desde 2020, está em desenvolvimento o Projeto Nossa Gente, que identificou
    os primeiros assentados nos livros de registro civil mais antigos dos
    municípios recém-emancipados, criando a categoria inauguradores do gentílico
    de cada um desses municípios. Esse material será compilado em material
    impresso e arquivos eletrônicos.
  </p>
  <p>
    Com o objetivo de contribuir para reposição de parte da biodiversidade
    regional, constituída originalmente pelo bioma Mata Atlântica, desenvolvem-
    se estudos sobre o jatobá (Hymenaea courbaril) e pratica-se o processamento
    desse fruto nativo, resultando em ações de empreendedorismo e de plantio
    dessa e de outras espécies nativas. Por meio do programa PIBIC CNPq, vários
    alunos bolsistas do Centro Universitário de Adamantina desenvolveram estudos
    sobre o Projeto Nossa Gente e sobre o jatobá, resultando em relatórios e
    artigos interessantes. Um desses trabalhos constitui-se numa Jatobateca, que
    reúne dezenas de fontes de estudos científicos, culinária, literatura e
    outras modalidades relacionadas a esse fruto nativo.
  </p>
  <p>
    Todas essas ações e outras já planejadas geram sistematizações, que precisam
    de um ambiente virtual concebido como repositório, resultando na necessidade
    de criação do web site latitude 21. Sua finalidade é reunir e disponibilizar
    essas informações geradas por estudantes do ensino superior e médio, bem
    como voluntários, disponibilizando-as para uso em trabalhos escolares,
    pesquisas científicas e também como memória.
  </p>
  <p>
    A criação do site é de responsabilidade de Latitude 21 Frutos Nativos e
    Produtos Regionais, em parceria com o Centro Universitário de
    Adamantina/FAI, que o mantém em funcionamento, cuja cidade sede é
    Adamantina/SP. Muitas das ações socioambientais desenvolvidas estão
    vinculadas à Rede de Educação Ambiental da Alta Paulista/REAP. A criação do
    site é da Agreste Consultoria Junior, vinculada à UFPE, campus Caruaru.
  </p>
  <p>
    A muitas mãos, mentes e corações, temos um produto permanentemente
    inacabado, cuja principal característica é reunir e disponibilizar
    informações organicamente coletadas e sistematizadas.
  </p>
</div>
