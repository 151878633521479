import { Component } from '@angular/core';

@Component({
  selector: 'app-antologia',
  templateUrl: './antologia.component.html',
  styleUrls: ['./antologia.component.scss'],
})
export class AntologiaComponent {
  title: string = 'Antologia de Poemas Musicados';
}
