<div class="sliderContainer">
  <swiper-container autoplay-delay="2500" [breakpoints]="{
      '560': {
        slidesPerView: 2,
      },
      '768': {
        slidesPerView: 3,
      },
      '1024': {
        slidesPerView: 4,
      },
      '1324': {
        slidesPerView: 5,
      }
    }" [loop]="true" [autoplay]="{
      delay: 2500,
      disableOnInteraction: false
    }">
    <swiper-slide *ngFor="let photo of photos">
      <img class="img" src="{{ photo }}" alt="" />
    </swiper-slide>
  </swiper-container>
</div>

<div class="containerHeader">
  <a href="/"><img class="imagemHeader" src="/assets/headerLatitude.png" alt="" />
  </a>
  <a href="/">
    <img class="imagemHeaderMobile" src="/assets/headerLatitudeMobile.png" alt="" />
  </a>
</div>